import axios from 'axios';
import { API_URL } from './api_url';
import authHeader from "@/services/auth-header";

class AuthService {
    auth() {
        return axios
            .post(API_URL + 'jobflow-login', {}, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
}
export default new AuthService();