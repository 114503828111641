import { createStore } from "vuex";
import VuexPersistence from 'vuex-persist';
import { auth } from "./auth.module";

const vuexPlugins = []

if (process.env.NODE_ENV === 'development') {
    const vuexLocal = new VuexPersistence({
        key: 'export-studio-state',
        storage: localStorage
    });
    vuexPlugins.push(vuexLocal.plugin)
}

const store = createStore({
    modules: {
        auth,
    },
    plugins: vuexPlugins
});

export default store;