import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-loading-overlay/dist/css/index.css';

//import "primevue/resources/themes/lara-light-blue/theme.css";
import "./views/primevue/studio_theme.scss"
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faClone, faTrash, faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';


library.add(faClone)
library.add(faTrash)
library.add(faPen)

const app = createApp(App)

app.component("font-awesome-icon", FontAwesomeIcon)
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(VueSweetalert2)

app.use(ConfirmationService)

app.use(PrimeVue)


app.mount('#app')