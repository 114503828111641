import axios from 'axios';
import { API_URL } from './api_url';
import authHeader from "@/services/auth-header";

export default class TargetService {
    getTargets() {
        return axios
            .get(API_URL + 'targets', {
                params: {
                    clientId: ''
                },
                headers: authHeader()
            })
            .then(response => {
                return response.data.Items;
            });
    }

    getTarget(targetId) {
        return axios
            .get(API_URL + `targets/${targetId}`, {
                params: {
                    clientId: ''
                },
                headers: authHeader()
            })
            .then(response => {
                return response.data.Item;
            });
    }

    deleteTarget(targetId) {
        return axios
            .delete(API_URL + `targets/${targetId}`, {
                params: {
                    clientId: ''
                },
                headers: authHeader()
            });
    }

    putTarget(target) {
        return axios
            .put(API_URL + `targets`, target, { headers: authHeader() })
    }

    generateSSHKeyPair(targetId, passphrase = '') {
        return axios
            .post(API_URL + `targets/${targetId}/ssh-keys`, { passphrase }, {
                headers: authHeader()
            });
    }

    deleteSSHKeyPair(targetId) {
        return axios
            .delete(API_URL + `targets/${targetId}/ssh-keys`, {
                headers: authHeader()
            });
    }
}