import AuthService from '../services/auth.service';
import SettingsService from '../services/target.service';

const initialState = {
    currentSchema: '',
    loggedIn: false,
    jobFlowClient: null,
    user: null,
    sessionTimeout: null
};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        setSchema({ commit }, schema) {
            commit('setSchema', schema);
        },
        logout({ commit }) {
            commit('logout');
        },
        login({ commit }) {
            commit('logout');
            return AuthService.auth().then(
                data => {
                    commit('login', data.jobflow?.user);
                    commit('setJobFlowClient', data.jobflow?.client);
                    commit('setSessionTimer', data.sessionTimeout);
                    return Promise.resolve(data);
                },
                error => {
                    commit('logout');
                    return Promise.reject(error);
                }
            );
        },
        settings(settings) {
            return SettingsService.post(settings).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        login(state, user) {
            state.loggedIn = true;
            state.sessionTimeout = null;
            state.user = user;
        },
        logout(state) {
            state.loggedIn = false;
            state.user = null;
        },
        setJobFlowClient(state, client) {
            state.jobFlowClient = client;
        },
        setSessionTimer(state, timeout) {
            state.sessionTimeout = timeout;
        },
        setSchema(state, schema) {
            state.currentSchema = schema;
        }
    }
};