import { createRouter, createWebHashHistory } from 'vue-router'
import TargetsView from '../views/TargetsView.vue'

const routes = [
  {
    alias: '/targets/',
    path: '/',
    name: 'targets',
    component: TargetsView,
  },
  {
    path: '/targets/:targetId',
    name: 'edit-target',  
    component: () => import('../views/EditTargetView.vue')
  },
  {
    path: '/schema-manager',
    name: 'schema-manager',
    component: () => import('../views/SchemaManagerView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router